import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/fonts.css';
import './styles/breakpoints.padding.css';
import './styles/color.system.css';
import './styles/index.css';
import './styles/main.color.css';
import './styles/border.css';
import './i18n';
import './aos';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
);

reportWebVitals();
