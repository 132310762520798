import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { tabletViews } from '../../utils/media';
import { headerHeight, headerIndex } from '../../utils/common';
import { uPointLandingStepNav } from '../../data/uPointLandingDetails';

const Wrapper = styled.div`
    position: fixed;
    top: ${headerHeight}rem;
    left: 50%;
    z-index: ${headerIndex - 1};
    transform: translateX(-50%);
    max-width: 110rem;
    width: 100%;
    background-color: var(--layout-progress-bar-bg);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    gap: 4px;
    padding: 4rem var(--main-side-padding);

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding: 4rem 3.2rem;
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        display: none;
    }
`;

const StepContainer = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
`;

const Circle = styled.div`
    position: relative;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: var(--neutral-400);

    &.active {
        background-color: var(--tertiary-400);
    }

    &.completed {
        background-color: var(--tertiary-500);
    }
`;

const Label = styled.p`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.1px;
    text-align: center;

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        font-size: 1.7rem;
        width: 10rem;

        &.lang-pl {
            font-size: 1.5rem;
        }
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        font-size: 1.6rem;

        &.lang-pl {
            font-size: 1.4rem;
        }
    }
`;

const Bar = styled.div`
    position: absolute;
    top: 0.4rem;
    left: 0;
    transform: translateX(55%) translateY(-50%);
    width: 96%;
    height: 2px;
    border-radius: 2px;
    background-color: var(--neutral-500);

    &.none {
        display: none;
    }

    &.active,
    &.completed {
        background-color: var(--tertiary-500);
    }
`;

interface Props {
    curLang: string;
    curStep: number;
    setCurStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepProgressNav: React.FC<Props> = ({ curLang, curStep, setCurStep }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            {uPointLandingStepNav.map(({ id, txt, order }) => (
                <StepContainer key={id} onClick={() => setCurStep(order)}>
                    <Circle
                        className={`${order === curStep ? 'active' : ''} ${order < curStep ? 'completed' : ''}`}
                    />

                    <Label className={`lang-${curLang}`}>{t(txt)}</Label>
                    <Bar
                        className={`${order === 5 ? 'none' : ''} ${order === curStep - 1 ? 'active' : ''} ${order < curStep - 1 ? 'completed' : ''}`}
                    />
                </StepContainer>
            ))}
        </Wrapper>
    );
};

export default StepProgressNav;
