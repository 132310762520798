import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UilCheck, UilArrowUpRight } from '@iconscout/react-unicons';
import { StyledWrapperStandard, StyledInner, ContentTextWrapper, Img } from '../../components/common.style';
import Button from '../../components/buttons/Button';
import { H5Intro, Text2 } from '../../components/common.styles.font';
import { desktopViews, tabletViews } from '../../utils/media';

const ContentWrapper = styled(StyledInner)`
    position: relative;
    display: grid;
    grid-template-columns: minmax(26rem, 40rem) minmax(6rem, 1fr) minmax(38rem, 5fr);
    grid-template-areas: 'text . img';
    align-items: center;

    button {
        max-width: fit-content;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        grid-template-columns: 1fr;
        grid-template-areas:
            'img'
            'text';
        row-gap: 4rem;
    }
`;

const TextBtnWrapper = styled.div`
    grid-area: text;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
    margin-bottom: 4rem;

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        margin-bottom: 3rem;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        gap: 1.6rem;
    }
`;

const ListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 1.2rem;

    p {
        color: var(--txt-inactive);
    }
`;

const Icon = styled.div`
    width: 2.4rem;

    .icon {
        width: 2.4rem;
        color: var(--tertiary-500);
    }
`;

const ImgWrapper = styled.div`
    grid-area: img;
    width: 100%;
`;

interface Point {
    id: string;
    txt: string;
}

interface Props {
    curLang: string;
    dataName: string;
    section: {
        intro: 'string';
        pointArr: Point[];
        img: any;
        btnTxt: 'string';
        btnAction: (arg0: string) => void;
    };
    openLightbox: (arg0: any) => void;
}

const ProductRequirementSection: React.FC<Props> = ({ curLang, dataName, section, openLightbox }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard className="bg-tertiary">
            <ContentWrapper>
                <TextBtnWrapper>
                    <ContentTextWrapper>
                        <H5Intro className={`lang-${curLang}`}>{t(section.intro)}</H5Intro>
                    </ContentTextWrapper>
                    <List>
                        {section.pointArr.map(({ id, txt }) => (
                            <ListItem key={id}>
                                <Icon>
                                    <UilCheck className="icon icon-check" />
                                </Icon>
                                <Text2 className={`lang-${curLang}`}>{t(txt)}</Text2>
                            </ListItem>
                        ))}
                    </List>

                    <Button fs="17" appearance="primary" width="fit-content" txt={t(section.btnTxt)} icon={<UilArrowUpRight className="icon icon-arrow-up" />} handleClick={() => section.btnAction(curLang)} />
                </TextBtnWrapper>

                <ImgWrapper>
                    <Img id={`product-${dataName}__img-requirement`} src={section.img} alt="product-requirement" onClick={() => openLightbox(section.img)} />
                </ImgWrapper>
            </ContentWrapper>
        </StyledWrapperStandard>
    );
};

export default ProductRequirementSection;
