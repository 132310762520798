import React, { useRef, useState, useEffect } from 'react';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { UilAngleDown } from '@iconscout/react-unicons';
import {
    Wrapper,
    Logo,
    Nav,
    ListItem,
    ListMenuBtn,
    ListMenu,
    LinkBtn,
    Title,
} from './style.js';
import BlubinaLogo from '../../assets/logo/logo-blubina.png';
import useClickOutside from '../../hooks/useClickOutside';
import { landingLanguages } from '../../utils/languages';
import { getLanguageFromURL } from '../../helpers/getLangFromURL';

interface Props {
    curLang: string;
    curLandingLang: string;
    setCurLandingLang: React.Dispatch<React.SetStateAction<string>>;
}

const LandingHeader: React.FC<Props> = ({
    curLang,
    curLandingLang,
    setCurLandingLang,
}) => {
    const { t } = useTranslation();
    const langBtnRef = useRef<HTMLLIElement | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const urlLang = getLanguageFromURL();

    // first render get localstorage language
    useEffect(() => {
        if (urlLang) {
            i18n.changeLanguage(urlLang);
            setCurLandingLang(urlLang);
        } else {
            setCurLandingLang(curLang);
        }
    }, []);

    const [isLangMenuOpen, setLangMenuOpen] = useState<boolean>(false);
    useClickOutside(langBtnRef, () => setLangMenuOpen(false));

    const handleLangClick = (code: string) => {
        setLangMenuOpen(false);
        setCurLandingLang(code);
        i18n.changeLanguage(code);
        localStorage.setItem('lang', JSON.stringify(code));
        const curPath = location.pathname;
        const newPath = curPath.replace(/^\/(en|de|pl)/, `/${code}`);
        navigate(newPath);
    };

    return (
        <Wrapper className="landing">
            <Logo href={`/${curLandingLang}`}>
                <img
                    className="hero-header_logo-img"
                    src={BlubinaLogo}
                    alt="logo"
                />
            </Logo>

            <Title className="long">{t('uPointDMS.landing.title.long')}</Title>
            <Title className="short">
                {t('uPointDMS.landing.title.short')}
            </Title>

            <Nav>
                <ListItem ref={langBtnRef}>
                    <ListMenuBtn
                        onClick={() => setLangMenuOpen(!isLangMenuOpen)}
                    >
                        <UilAngleDown className="icon icon-arrow-down" />
                        <span>{curLandingLang}</span>
                    </ListMenuBtn>

                    {isLangMenuOpen && (
                        <ListMenu className="header_lang-menu">
                            {landingLanguages.map(({ id, code, name }) => {
                                return (
                                    <li key={id}>
                                        <LinkBtn
                                            className={
                                                i18n.resolvedLanguage === code
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() =>
                                                handleLangClick(code)
                                            }
                                        >
                                            {name}
                                        </LinkBtn>
                                    </li>
                                );
                            })}
                        </ListMenu>
                    )}
                </ListItem>
            </Nav>
        </Wrapper>
    );
};

export default LandingHeader;
