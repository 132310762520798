import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from './buttons/Button';
import { tabletViews } from '../utils/media';
import { bottomIndex, bottomHeight } from '../utils/common';

export const Wrapper = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: ${bottomIndex};
    width: 100%;
    height: ${bottomHeight}rem;
    background: var(--layout-bottom-bg);
    border-top: 1px solid var(--layout-bottom-bd);
    display: flex;
    align-items: center;
    padding: 0 2.6rem;
    box-shadow: var(--layout-bottom-shadow);

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        padding: 0 1.6rem;
        border-top: none;
        box-shadow: none;
    }
`;

export const Inner = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;

    display: grid;

    &.col-3 {
        grid-template-columns:
            minmax(17rem, max-content) 1fr minmax(17rem, max-content)
            minmax(17rem, max-content);
        grid-template-areas: 'back . next go';
    }

    &.col-2.no-back {
        grid-template-columns: 1fr minmax(17rem, max-content) minmax(
                17rem,
                max-content
            );
        grid-template-areas: '. next go';
    }

    &.col-2.no-next {
        grid-template-columns: minmax(17rem, max-content) 1fr minmax(
                17rem,
                max-content
            );
        grid-template-areas: 'back . go';
    }

    button {
        line-height: 1.5;
        padding: 0.6rem 1.6rem;
        height: fit-content;
        white-space: nowrap;
        width: 100%;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        gap: 1rem;

        &.col-3 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas: 'back next go';
        }

        &.col-2.no-back {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'next go';
        }

        &.col-2.no-next {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'back go';
        }

        button {
            padding: 0.6rem 1.2rem;
        }
    }
`;

export const BackBtnWrapper = styled.div`
    grid-area: back;
`;

export const NextBtnWrapper = styled.div`
    grid-area: next;
`;

export const GoToBtnWrapper = styled.div`
    grid-area: go;
`;

interface Props {
    curLandingLang: string;
    curStep: number;
    setCurStep: React.Dispatch<React.SetStateAction<number>>;
}

const LandingBottom: React.FC<Props> = ({
    curLandingLang,
    curStep,
    setCurStep,
}) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Inner
                className={`${curStep <= 1 ? 'col-2 no-back' : curStep >= 5 ? 'col-2 no-next' : 'col-3'}`}
            >
                {curStep > 1 && (
                    <BackBtnWrapper>
                        <Button
                            fs="15"
                            appearance="primary-outline"
                            txt={t('uPointDMS.landing.btn.back')}
                            handleClick={() => setCurStep((prev) => prev - 1)}
                        />
                    </BackBtnWrapper>
                )}

                {curStep < 5 && (
                    <NextBtnWrapper>
                        <Button
                            fs="15"
                            appearance="primary"
                            txt={t('uPointDMS.landing.btn.next')}
                            handleClick={() => setCurStep((prev) => prev + 1)}
                        />
                    </NextBtnWrapper>
                )}

                <GoToBtnWrapper>
                    <Button
                        fs="15"
                        appearance="primary"
                        txt={t('uPointDMS.landing.btn.openUPointDMS')}
                        handleClick={() =>
                            window.open(
                                `https://app.upoint-dms.com/${curLandingLang}/sharepoint`,
                                '_blank',
                            )
                        }
                    />
                </GoToBtnWrapper>
            </Inner>
        </Wrapper>
    );
};

export default LandingBottom;
