import { v4 as uuidv4 } from 'uuid';
// Images
import InitialAccess1Img from '../assets/products/uPointDMS/imgs/permissions_request_1.png';
import InitialAccess2Img from '../assets/products/uPointDMS/imgs/permissions_request_2.png';
import SelectResourceEnImg from '../assets/products/uPointDMS/imgs/select-resource_en.png';
import SelectResourceDeImg from '../assets/products/uPointDMS/imgs/select-resource_de.png';
import SelectResourcePlImg from '../assets/products/uPointDMS/imgs/select-resource_pl.png';
import SelectProfileEnImg from '../assets/products/uPointDMS/imgs/select-profile_en.png';
import SelectProfileDeImg from '../assets/products/uPointDMS/imgs/select-profile_de.png';
import SelectProfilePlImg from '../assets/products/uPointDMS/imgs/select-profile_pl.png';
import DropFileEnImg from '../assets/products/uPointDMS/imgs/drop-file_en.png';
import DropFileDeImg from '../assets/products/uPointDMS/imgs/drop-file_de.png';
import DropFilePlImg from '../assets/products/uPointDMS/imgs/drop-file_pl.png';

export const uPointLandingStepNav = [
    { id: uuidv4(), order: 1, txt: 'uPointDMS.landing.generalTerms.title' },
    { id: uuidv4(), order: 2, txt: 'uPointDMS.landing.initialAccess.title' },
    {
        id: uuidv4(),
        order: 3,
        txt: 'uPointDMS.landing.selectResource.title',
    },
    { id: uuidv4(), order: 4, txt: 'uPointDMS.landing.selectProfile.title' },
    {
        id: uuidv4(),
        order: 5,
        txt: 'uPointDMS.landing.settings.title',
    },
];

export const uPointLandingDetails = [
    {
        id: uuidv4(),
        order: 1,
        title: 'uPointDMS.landing.generalTerms.title',
        sectionList: [
            {
                id: uuidv4(),
                title: 'uPointDMS.landing.generalTerms.point1Title',
                img: null,
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.generalTerms.point1Desc',
                    },
                ],
            },
            {
                id: uuidv4(),
                title: 'uPointDMS.landing.generalTerms.point2Title',
                img: null,
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.generalTerms.point2Desc1',
                    },
                    {
                        link: null,
                        txt: 'uPointDMS.landing.generalTerms.point2Desc2',
                    },
                ],
            },
            {
                id: uuidv4(),
                title: 'uPointDMS.landing.generalTerms.point2Title',
                img: null,
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.generalTerms.point3Desc',
                    },
                ],
            },
            {
                id: uuidv4(),
                title: 'uPointDMS.landing.generalTerms.point4Title',
                img: null,
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.generalTerms.point4Desc1',
                    },
                    {
                        link: null,
                        txt: 'uPointDMS.landing.generalTerms.point4Desc2',
                    },
                ],
            },
            {
                id: uuidv4(),
                title: 'uPointDMS.landing.generalTerms.point5Title',
                img: null,
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.generalTerms.point5Desc',
                    },
                ],
            },
        ],
    },
    {
        id: uuidv4(),
        order: 2,
        title: 'uPointDMS.landing.initialAccess.title',
        sectionList: [
            {
                id: uuidv4(),
                title: null,
                img: {
                    en: InitialAccess1Img,
                    de: InitialAccess1Img,
                    pl: InitialAccess1Img,
                },
                imgDesc: '',
                txtList: [
                    {
                        link: 'https://app.upoint-dms.com/',
                        txt: 'uPointDMS.landing.initialAccess.desc1',
                    },
                    {
                        link: null,
                        txt: 'uPointDMS.landing.initialAccess.desc2',
                    },
                ],
            },
            {
                id: uuidv4(),
                title: null,
                img: {
                    en: InitialAccess2Img,
                    de: InitialAccess2Img,
                    pl: InitialAccess2Img,
                },
                imgDesc: '',
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.initialAccess.desc3',
                    },
                ],
            },
        ],
    },
    {
        id: uuidv4(),
        order: 3,
        title: 'uPointDMS.landing.selectResource.title',
        sectionList: [
            {
                id: uuidv4(),
                title: null,
                img: {
                    en: SelectResourceEnImg,
                    de: SelectResourceDeImg,
                    pl: SelectResourcePlImg,
                },
                imgDesc: '',
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.selectResource.desc',
                    },
                ],
            },
        ],
    },
    {
        id: uuidv4(),
        order: 4,
        title: 'uPointDMS.landing.selectProfile.title',
        sectionList: [
            {
                id: uuidv4(),
                title: null,
                img: {
                    en: SelectProfileEnImg,
                    de: SelectProfileDeImg,
                    pl: SelectProfilePlImg,
                },
                imgDesc: '',
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.selectProfile.desc',
                    },
                ],
            },
        ],
    },
    {
        id: uuidv4(),
        order: 5,
        title: 'uPointDMS.landing.settings.title',
        sectionList: [
            {
                id: uuidv4(),
                title: null,
                img: {
                    en: DropFileEnImg,
                    de: DropFileDeImg,
                    pl: DropFilePlImg,
                },
                imgDesc: '',
                txtList: [
                    {
                        link: null,
                        txt: 'uPointDMS.landing.settings.desc',
                    },
                ],
            },
        ],
    },
];
