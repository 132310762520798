import React, { useState } from 'react';
import styled from 'styled-components';
import Lightbox from '../../components/Lightbox';
import LandingHeader from '../../components/Header/LandingHeader';
import StepProgressNav from '../../components/Nav/StepProgressNav';
import LandingBottom from '../../components/LandingBottom';
import { bottomHeight, headerHeight } from '../../utils/common';
import { tabletViews } from '../../utils/media';
import StepProgressBottomNav from '../../components/Nav/StepProgressBottomNav';
import Slider from '../../components/Slider';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: var(--landing-bg);
`;

export const Inner = styled.div`
    max-width: 100rem;
    margin: auto;
    padding: ${headerHeight + 14}rem var(--main-side-padding) ${bottomHeight + 6}rem;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        padding: ${headerHeight + 14}rem var(--main-side-padding) ${bottomHeight + 6}rem;
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        padding: ${headerHeight}rem 0 ${bottomHeight + 8}rem;
    }
`;

interface Props {
    curLang: string;
}

const UPointLandingPage: React.FC<Props> = ({ curLang }) => {
    const [curLandingLang, setCurLandingLang] = useState<string>('en');
    const [curStep, setCurStep] = useState<number>(1);
    const [popupImg, setPopupImg] = useState<any>(null);
    const [isLightboxOpen, setLightboxOpen] = useState(false);

    const openLightbox = (targetImg: any) => {
        setLightboxOpen(true);
        setPopupImg(targetImg);
        document.body.classList.add('popup-open');
    };

    const closeLightbox = () => {
        setPopupImg(null);
        setLightboxOpen(false);
        document.body.classList.remove('popup-open');
    };

    return (
        <>
            <LandingHeader curLang={curLang} curLandingLang={curLandingLang} setCurLandingLang={setCurLandingLang} />

            <Lightbox popupImg={popupImg} isLightboxOpen={isLightboxOpen} closeLightbox={closeLightbox} />

            <Wrapper>
                <StepProgressNav curLang={curLang} curStep={curStep} setCurStep={setCurStep} />
                <Inner>
                    <Slider curLang={curLang} openLightbox={openLightbox} curStep={curStep} setCurStep={setCurStep} />
                </Inner>

                <StepProgressBottomNav curStep={curStep} setCurStep={setCurStep} />
                <LandingBottom curLandingLang={curLandingLang} curStep={curStep} setCurStep={setCurStep} />
            </Wrapper>
        </>
    );
};

export default UPointLandingPage;
