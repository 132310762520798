import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LandingContent from '../sections/landingContent/LandingContent';
import { uPointLandingDetails } from '../data/uPointLandingDetails';
import useWindowDimensions from '../hooks/useWindowDemision';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: height 0.5s ease;
`;

const Inner = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
`;

const Slide = styled.div`
    min-width: 100%;
    transition: transform 0.5s ease;
`;

interface Props {
    curLang: string;
    curStep: number;
    setCurStep: React.Dispatch<React.SetStateAction<number>>;
    openLightbox: (arg0: any) => void;
}

const Slider: React.FC<Props> = ({
    curLang,
    curStep,
    setCurStep,
    openLightbox,
}) => {
    const startingSlideIdx = 1;
    const totalSlideCount = uPointLandingDetails.length;

    const { width } = useWindowDimensions();
    const [sliderHeight, setSliderHeight] = useState<number>(0);

    const [touchStart, setTouchStart] = useState<number | null>(null);
    const [touchEnd, setTouchEnd] = useState<number | null>(null);
    const minSwipeDistance = 50;

    useEffect(() => {
        adjustSliderHeight();
    }, [curStep, width]);

    const adjustSliderHeight = () => {
        const pageEls = document.querySelectorAll('.page');
        const targetPage = pageEls[curStep - 1];
        if (targetPage) {
            const targetPageRect = targetPage.getBoundingClientRect();
            const targetPageHeight = targetPageRect.height;

            setSliderHeight(targetPageHeight);
        }
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e: React.TouchEvent) =>
        setTouchEnd(e.targetTouches[0].clientX);

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            setCurStep((prev) => {
                if (prev === totalSlideCount) {
                    return prev;
                } else {
                    return prev + 1;
                }
            });
        } else if (isRightSwipe) {
            setCurStep((prev) => {
                if (prev === startingSlideIdx) {
                    return prev;
                } else {
                    return prev - 1;
                }
            });
        }
    };

    return (
        <Wrapper className="hhhs" style={{ height: `${sliderHeight}px` }}>
            <Inner
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {uPointLandingDetails.map(
                    ({ id, order, title, sectionList }) => (
                        <Slide
                            key={id}
                            style={{
                                transform: `translateX(${-100 * (curStep - 1)}%)`,
                            }}
                        >
                            <LandingContent
                                curLang={curLang}
                                curStep={curStep}
                                order={order}
                                contentTitle={title}
                                sectionList={sectionList}
                                openLightbox={openLightbox}
                            />
                        </Slide>
                    ),
                )}
            </Inner>
        </Wrapper>
    );
};

export default Slider;
