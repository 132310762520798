import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styled from 'styled-components';
import {
    UilTimes,
    UilSearchPlus,
    UilSearchMinus,
} from '@iconscout/react-unicons';
import { imageLightboxZIndex } from '../utils/common';
import { tabletViews } from '../utils/media';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    opacity: 0;
    z-index: -1;
    transform: opacity 0.3s ease;

    &.active {
        opacity: 1;
        z-index: ${imageLightboxZIndex};
    }
    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        place-items: initial;
        padding-top: 15rem;
    }
`;

const IconBtnsWrapper = styled.div`
    position: absolute;
    top: 2rem;
    right: 2.4rem;
    z-index: ${imageLightboxZIndex};
    display: flex;
    align-items: center;
    gap: 1.4rem;
`;

const IconBtn = styled.button`
    color: var(--neutral-500);

    .icon {
        width: 2.4rem;
    }

    .icon-zoom-in,
    .icon-zoom-out {
        width: 2rem;
    }

    &:hover {
        color: var(--neutral-100);
    }

    &:active {
        color: var(--neutral-50);
    }
`;

const LightboxInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: ${imageLightboxZIndex - 1};
`;

const ImgWrapper = styled.div`
    z-index: ${imageLightboxZIndex};
    cursor: grab;
`;

const LightboxImg = styled.img`
    max-width: 140rem;
    width: 100vw;
    height: 0;
    opacity: 0;
    transition:
        width 0.35s ease,
        height 0.35s ease,
        position 0.35s ease;

    &.active {
        opacity: 1;
        width: 100vw;
        height: auto;
    }
`;

interface Props {
    popupImg: any;
    isLightboxOpen: boolean;
    closeLightbox: () => void;
}

const Lightbox: React.FC<Props> = ({
    popupImg,
    isLightboxOpen,
    closeLightbox,
}) => {
    return (
        <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform }) => (
                <Wrapper
                    id="product__lightbox"
                    className={isLightboxOpen ? 'active' : ''}
                >
                    <LightboxInner
                        onClick={() => {
                            closeLightbox();
                            resetTransform();
                        }}
                    />
                    <IconBtnsWrapper>
                        <IconBtn
                            onClick={() => {
                                zoomOut();
                            }}
                        >
                            <UilSearchMinus className="icon icon-zoom-out" />
                        </IconBtn>
                        <IconBtn
                            onClick={() => {
                                zoomIn();
                            }}
                        >
                            <UilSearchPlus className="icon icon-zoom-in" />
                        </IconBtn>
                        <IconBtn
                            onClick={() => {
                                closeLightbox();
                                resetTransform();
                            }}
                        >
                            <UilTimes className="icon icon-close" />
                        </IconBtn>
                    </IconBtnsWrapper>

                    <ImgWrapper className={isLightboxOpen ? 'active' : ''}>
                        <TransformComponent>
                            <LightboxImg
                                id="product__lightbox-img"
                                className={isLightboxOpen ? 'active' : ''}
                                src={popupImg}
                                alt="product-explanation"
                            />
                        </TransformComponent>
                    </ImgWrapper>
                </Wrapper>
            )}
        </TransformWrapper>
    );
};

export default Lightbox;
