import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Subtitle1, Text1 } from '../../components/common.styles.font';
import { tabletViews } from '../../utils/media';

const Wrapper = styled.div`
    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        &.without-img {
            padding: 3rem var(--main-side-padding) 0;
        }
    }
`;

const Title = styled.div`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 0.1px;
    margin-bottom: 3rem;
    color: var(--txt-title);

    &.title-sm {
        display: none;
    }

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 2.7rem;
        font-weight: 500;
        line-height: 1.7;
        letter-spacing: 0px;
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 2.6rem;
        margin-bottom: 1.4rem;

        &.lang-pl {
            font-size: 2.3rem;
        }

        &.title-lg {
            display: none;
        }

        &.title-sm {
            display: block;
        }
    }
`;

const Content = styled.div`
    .section-title {
        font-weight: 600;
        color: #000;
        margin-bottom: 1rem;
        line-height: 1.3;
    }

    .section-details {
        /* font-weight: 500; */
        color: var(--txt-active);
        margin-bottom: 1.6rem;
    }
`;

const Section = styled.div`
    margin-bottom: 3.4rem;

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        margin-bottom: 5rem;
    }
`;

const TextContainer = styled.div`
    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        &.with-img {
            padding: 0 var(--main-side-padding);
        }
    }
`;

const Text = styled(Text1)`
    font-size: 2.1rem;

    &.lang-pl {
        font-size: 1.9rem;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 2rem;

        &.lang-pl {
            font-size: 1.8rem;
        }
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 1.9rem;

        &.lang-pl {
            font-size: 1.7rem;
        }
    }
`;

const ImgWrapper = styled.div`
    display: grid;
    place-content: center;
    grid-template-columns: 1fr;
    margin-bottom: 3rem;
`;

export const Img = styled.img`
    cursor: zoom-in;
    width: 100%;
    border: 1px solid var(--neutral-300);
    border-radius: var(--img-bd-r);

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        border: none;
        border-radius: 0;
    }
`;

export const Link = styled.a`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 2.1rem;
    font-weight: 600;
    letter-spacing: 0.2px;
    word-break: keep-all;
    white-space: nowrap;
    color: var(--link-txt);
    margin: 0 2px;
    padding-bottom: 1px;
    border-bottom: 1px solid var(--link-txt);

    &:hover {
        color: var(--link-txt-h);
        border-bottom: 1px solid var(--link-txt-h);
    }

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 1.9rem;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 0.1px;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 2rem;

        &.lang-pl {
            font-size: 1.8rem;
        }
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        font-size: 1.9rem;

        &.lang-pl {
            font-size: 1.7rem;
        }
    }
`;

interface SectionObj {
    id: string;
    title: string | null;
    img: any;
    // imgDesc?: string;
    txtList: any[];
}

interface Props {
    curLang: string;
    curStep: number;
    order: number;
    contentTitle: string;
    sectionList: SectionObj[];
    openLightbox: (arg0: any) => void;
}

const LandingContent: React.FC<Props> = ({
    curLang,
    curStep,
    order,
    contentTitle,
    sectionList,
    openLightbox,
}) => {
    const { t } = useTranslation();

    return order === 1 ? (
        <Wrapper
            className={`${curStep === order ? 'active' : ''} without-img page`}
        >
            <Title className={`lang-${curLang}`}>
                {order}. {t(contentTitle)}
            </Title>

            <Content>
                {sectionList.map(({ id, title, img, txtList }) => (
                    <Section key={id}>
                        {img && (
                            <ImgWrapper>
                                <Img
                                    src={img[curLang]}
                                    alt=""
                                    onClick={() => openLightbox(img[curLang])}
                                />
                            </ImgWrapper>
                        )}
                        <TextContainer>
                            {title && (
                                <Subtitle1
                                    className={`lang-${curLang} section-title`}
                                >
                                    {t(title)}
                                </Subtitle1>
                            )}

                            <>
                                {txtList.map(({ link, txt }, idx) =>
                                    link ? (
                                        <Text
                                            key={`${id}-txt-${idx}`}
                                            className={`lang-${curLang} section-details`}
                                        >
                                            <Trans i18nKey={txt}>
                                                <Link
                                                    className={`lang-${curLang}`}
                                                    href={link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {link}
                                                </Link>
                                            </Trans>
                                        </Text>
                                    ) : (
                                        <Text
                                            key={`${id}-txt-${idx}`}
                                            className={`lang-${curLang} section-details`}
                                        >
                                            {t(txt)}
                                        </Text>
                                    ),
                                )}
                            </>
                        </TextContainer>
                    </Section>
                ))}

                <Section>
                    <Text className={`lang-${curLang} section-details`}>
                        <Trans i18nKey="uPointDMS.landing.moreInfo">
                            <Link
                                className={`lang-${curLang}`}
                                href={`/${curLang}/privacy-policy`}
                                target="_blank"
                                rel="noreferrer"
                            />
                        </Trans>
                    </Text>
                </Section>
            </Content>
        </Wrapper>
    ) : (
        <Wrapper
            className={`${curStep === order ? 'active' : ''} with-img page`}
        >
            <Title className={`lang-${curLang} title-lg`}>
                {order}. {t(contentTitle)}
            </Title>

            <Content>
                {sectionList.map(({ id, title, img, txtList }, idx) => (
                    <Section key={id}>
                        {img && (
                            <ImgWrapper>
                                <Img
                                    src={img[curLang]}
                                    alt=""
                                    onClick={() => openLightbox(img[curLang])}
                                />
                            </ImgWrapper>
                        )}
                        <TextContainer className="with-img">
                            {title && (
                                <Subtitle1
                                    className={`lang-${curLang} section-title`}
                                >
                                    {t(title)}
                                </Subtitle1>
                            )}

                            {idx === 0 && (
                                <Title className={`lang-${curLang} title-sm`}>
                                    {order}. {t(contentTitle)}
                                </Title>
                            )}

                            <>
                                {txtList.map(({ link, txt }, idx) =>
                                    link ? (
                                        <Text
                                            key={`${id}-txt-${idx}`}
                                            className={`lang-${curLang} section-details`}
                                        >
                                            <Trans i18nKey={txt}>
                                                <Link
                                                    className={`lang-${curLang}`}
                                                    href={link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {link}
                                                </Link>
                                            </Trans>
                                        </Text>
                                    ) : (
                                        <Text
                                            key={`${id}-txt-${idx}`}
                                            className={`lang-${curLang} section-details`}
                                        >
                                            {t(txt)}
                                        </Text>
                                    ),
                                )}
                            </>
                        </TextContainer>
                    </Section>
                ))}
            </Content>
        </Wrapper>
    );
};

export default LandingContent;
