import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledWrapperStandard, StyledInner, TitleWrapper } from '../../components/common.style';
import { desktopViews, tabletViews } from '../../utils/media';
import { H4Title, Text1 } from '../../components/common.styles.font';

const Wrapper = styled(StyledWrapperStandard)``;

const Inner = styled(StyledInner)`
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        flex-wrap: wrap;
        justify-content: center;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        gap: 2.4rem;
    }
`;

const Card = styled.div`
    width: 20rem;
    padding: 1.2rem;
    background: #fff;
    border-radius: var(--overview-bd-r-lg);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    padding: 2.4rem 2.2rem;
    box-shadow: 0 4px 20px 0 rgba(216, 216, 244, 0.35);

    &.card-de {
        padding: 2rem 2.2rem;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        width: 18rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        width: 100%;
        border-radius: var(--overview-bd-r-sm);
        display: grid;
        grid-template-columns: 4.5rem 1fr;
        gap: 1rem;
        padding: 2rem 2.2rem 2rem 1rem;

        &.card-de {
            padding: 2rem 2.2rem 2rem 1rem;
        }
    }
`;

const CardIcon = styled.div`
    color: var(--card-primary-icon);
    width: 4.5rem;
    height: 4.5rem;
    display: grid;
    place-items: center;

    .icon-repeated-docs {
        transform: scale(0.9);
    }

    .icon-location {
        transform: scale(1.2);
    }

    .icon-file,
    .icon-channel {
        transform: scale(1.3);
    }

    .icon-network {
        transform: scale(1.4);
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        .icon-location {
            transform: scale(1.1);
        }

        .icon-file,
        .icon-channel {
            transform: scale(1.2);
        }

        .icon-network {
            transform: scale(1.3);
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        .icon-repeated-docs {
            transform: scale(0.85);
        }

        .icon-location {
            transform: scale(1);
        }

        .icon-file,
        .icon-channel {
            transform: scale(1.1);
        }

        .icon-network {
            transform: scale(1.2);
        }
    }
`;

const CardText = styled(Text1)`
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0;
    text-align: center;

    &.lang-pl {
        font-weight: 500;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        text-align: left;
    }
`;

interface Overview {
    id: string;
    icon: any;
    name: string;
    txt: string;
}

interface Props {
    curLang: string;
    section: Overview[];
}

const ProductOverviewSection: React.FC<Props> = ({ curLang, section }) => {
    const { t } = useTranslation();

    return (
        <Wrapper className="bg-primary">
            <TitleWrapper className="title-card-wrapper">
                <H4Title className="title-span-primary">
                    <span>{t('common.section.overview')}</span>
                </H4Title>
            </TitleWrapper>

            <Inner>
                {section.map(({ id, icon, txt }) => (
                    <Card key={id} className={`card-${curLang}`}>
                        <CardIcon>{icon}</CardIcon>
                        <CardText className={`lang-${curLang}`}>{t(txt)}</CardText>
                    </Card>
                ))}
            </Inner>
        </Wrapper>
    );
};

export default ProductOverviewSection;
