import { v4 as uuidv4 } from 'uuid';
import { UilFileAlt, UilFolderNetwork, UilLocationArrow, UilChannel } from '@iconscout/react-unicons';
// Images
import HeroEnImg from '../assets/products/uPointDMS/imgs/hero-en.png';
import HeroDeImg from '../assets/products/uPointDMS/imgs/hero-de.png';
import MoreVidsImg from '../assets/products/uPointDMS/imgs/placeholder-vimeo.png';
import RequirementImg from '../assets/products/uPointDMS/imgs/requirement.png';
import RepeatedDocs from '../assets/icons/repeated-docs.svg';
// Videos
import IntroVid from '../assets/products/uPointDMS/vids/uPointDMS_Intro.mov';
import NamingLogicVid from '../assets/products/uPointDMS/vids/uPoint-DMS_Naming_Logic.mov';
import SmartFolderTemplatesVid from '../assets/products/uPointDMS/vids/uPoint-DMS_Smart_Folder_Templates.mov';
import FileIdentifierVid from '../assets/products/uPointDMS/vids/uPoint-DMS_File_Identifier.mov';
import FriendlyTaggingVid from '../assets/products/uPointDMS/vids/uPoint-DMS_User_Friendly_Tagging.mov';
import SeamlessIntegrationrVid from '../assets/products/uPointDMS/vids/uPoint-DMS_Seamless_Integration.mov';

export const uPointProductDetails = {
    appTitle: 'product.uPointDMS.helmet.title',
    appDesc: 'product.uPointDMS.helmet.desc',
    dataName: 'uPoint',
    productName: 'uPoint DMS',
    heroDetails: {
        title: 'product.uPointDMS.main.title',
        subtitle: 'product.uPointDMS.main.subtitle',
        desc: 'product.uPointDMS.main.desc',
        img: {
            en: [HeroEnImg, HeroEnImg],
            de: [HeroDeImg, HeroDeImg],
            pl: [HeroEnImg, HeroEnImg],
            zh: [HeroEnImg, HeroEnImg]
        },
        firstBtnTxt: 'common.btn.learnMore',
        firstBtnAction: () => {
            const targetEl = document.getElementById('product-uPoint__intro');
            targetEl?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        secondBtnTxt: 'common.btn.freeTrial',
        secondBtnAction: (curLang) => window.open(`/${curLang}/landing`, '_blank')
    },
    intro: {
        desc: 'product.uPointDMS.intro.desc',
        video: IntroVid
    },
    overview: [
        {
            id: uuidv4(),
            icon: <UilFileAlt className="icon icon-file" />,
            name: 'File naming conventions',
            txt: 'product.uPointDMS.overview.point1'
        },
        {
            id: uuidv4(),
            icon: <UilFolderNetwork className="icon icon-network" />,
            name: 'Smart folder structures',
            txt: 'product.uPointDMS.overview.point2'
        },
        {
            id: uuidv4(),
            icon: <img className="icon icon-repeated-docs" src={RepeatedDocs} alt="icon-repeated-docs" />,
            name: 'Automate repeated docs',
            txt: 'product.uPointDMS.overview.point3'
        },
        {
            id: uuidv4(),
            icon: <UilLocationArrow className="icon icon-location" />,
            name: 'Intuitive and convenient',
            txt: 'product.uPointDMS.overview.point4'
        },
        {
            id: uuidv4(),
            icon: <UilChannel className="icon icon-channel" />,
            name: 'Ready for your existing sites',
            txt: 'product.uPointDMS.overview.point5'
        }
    ],
    explanationDetails: null,
    moreVideos: {
        desc: 'product.uPointDMS.video.desc',
        img: MoreVidsImg,
        btnAction: () => window.open('https://vimeo.com/user219937106')
    },
    vidDetails: null,
    requirement: {
        intro: 'product.uPointDMS.requirement.intro',
        pointArr: [
            {
                id: uuidv4(),
                txt: 'product.uPointDMS.requirement.point1'
            },
            {
                id: uuidv4(),
                txt: 'product.uPointDMS.requirement.point2'
            }
        ],
        img: RequirementImg,
        btnTxt: 'common.btn.startFreeTrial',
        btnAction: (curLang) => window.open(`/${curLang}/landing`, '_blank')
    },
    pricingSection: {
        intro: 'product.uPointDMS.pricing.intro',
        sideIntro: 'product.uPointDMS.pricing.sideIntro',
        pricingArr: [
            {
                id: uuidv4(),
                idx: 1,
                category: 'basic',
                title: 'common.pricing.busineseBasic',
                price: {
                    title: 'product.uPointDMS.pricing.basicPrice',
                    unit: 'product.uPointDMS.pricing.basicPriceUnit',
                    method: 'product.uPointDMS.pricing.basicPriceMethod'
                },
                customPrice: null,
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.basicPoint1'
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.basicPoint2'
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.basicPoint3'
                    }
                ],
                isFreeTrialBtn: true
            },
            {
                id: uuidv4(),
                idx: 2,
                category: 'enterprise',
                title: 'common.pricing.busineseEnterprise',
                price: null,
                customPrice: 'product.uPointDMS.pricing.enterprisePrice',
                featuresArr: [
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.enterprisePoint1'
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.enterprisePoint2'
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.enterprisePoint3'
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.enterprisePoint4'
                    },
                    {
                        id: uuidv4(),
                        txt: 'product.uPointDMS.pricing.enterprisePoint5'
                    }
                ],
                isFreeTrialBtn: true
            }
        ]
    },
    requestSection: {
        product: 'uPoint DMS',
        services: null,
        isReady: true,
        btnTxt: 'common.btn.getStartedWithfreeTrial',
        btnAction: (curLang) => window.open(`/${curLang}/landing`, '_blank')
    }
};

export const uPointFeatureDetails = {
    moreBtnSection: {
        txt: 'product.uPointDMS.feature.intro',
        targetEl: 'product-uPoint_video-section'
    },
    intro: {
        section1: [
            {
                id: uuidv4(),
                txt: 'product.uPointDMS.feature.intro'
            }
        ]
    },
    nav: {
        section1: [
            {
                id: uuidv4(),
                idx: '1',
                target: 'feature_document-naming-for-your-business-logic',
                txt: 'product.uPointDMS.feature.point1Title'
            },
            {
                id: uuidv4(),
                idx: '2',
                target: 'feature_smart-folder-templates-for-every-complexity',
                txt: 'product.uPointDMS.feature.point2Title'
            },
            {
                id: uuidv4(),
                idx: '3',
                target: 'feature_recognition-of-repeated-documents',
                txt: 'product.uPointDMS.feature.point3Title'
            },
            {
                id: uuidv4(),
                idx: '4',
                target: 'feature_user-friendly-tagging-and-filing',
                txt: 'product.uPointDMS.feature.point4Title'
            },
            {
                id: uuidv4(),
                idx: '5',
                target: 'feature_seamless-integration-with-your-sharePoint-sites',
                txt: 'product.uPointDMS.feature.point5Title'
            }
        ]
    },
    content: {
        section1: [
            {
                id: 'feature_document-naming-for-your-business-logic',
                idx: '1',
                title: 'product.uPointDMS.feature.point1Title',
                desc: 'product.uPointDMS.feature.point1Desc',
                img: null,
                alt: null,
                video: NamingLogicVid,
                videoDesc: null
            },
            {
                id: 'feature_smart-folder-templates-for-every-complexity',
                idx: '2',
                title: 'product.uPointDMS.feature.point2Title',
                desc: 'product.uPointDMS.feature.point2Desc',
                img: null,
                alt: null,
                video: SmartFolderTemplatesVid,
                videoDesc: null
            },
            {
                id: 'feature_recognition-of-repeated-documents',
                idx: '3',
                title: 'product.uPointDMS.feature.point3Title',
                desc: 'product.uPointDMS.feature.point3Desc',
                img: null,
                alt: null,
                video: FileIdentifierVid,
                videoDesc: null
            },
            {
                id: 'feature_user-friendly-tagging-and-filing',
                idx: '4',
                title: 'product.uPointDMS.feature.point4Title',
                desc: 'product.uPointDMS.feature.point4Desc',
                img: null,
                alt: null,
                video: FriendlyTaggingVid,
                videoDesc: null
            },
            {
                id: 'feature_seamless-integration-with-your-sharePoint-sites',
                idx: '5',
                title: 'product.uPointDMS.feature.point5Title',
                desc: 'product.uPointDMS.feature.point5Desc',
                img: null,
                alt: null,
                video: SeamlessIntegrationrVid,
                videoDesc: null
            }
        ]
    }
};
