import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import UPointPrivacyPage from './pages/uPointPrivacy/UPointPrivacyPage';
import UPointTermsPage from './pages/uPointTerms/UPointTermsPage';
import RootPage from './pages/root/RootPage';
import UPointLandingPage from './pages/uPointLanding/UPointLandingPage';
import ProductPage from './pages/product/ProductPage';
import { getLanguageFromURL } from './helpers/getLangFromURL.js';
import { uPointFeatureDetails, uPointProductDetails } from './data/uPointProductDetails';

const App = () => {
    const [curLang, setCurLang] = useState<string>('en');
    const [curView, setCurView] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const urlLang = getLanguageFromURL();

    useEffect(() => {
        setCurView('product');
    }, []);

    // first render get localstorage language
    useEffect(() => {
        const getSavedLanguage = () => {
            const savedLang = localStorage.getItem('lang');
            const defaultLang = 'de';
            const parsedLang = savedLang ? JSON.parse(savedLang) : defaultLang;

            updateLanguage(parsedLang);
            navigate(`/${parsedLang}`);
        };

        const updateLanguage = (lang: any) => {
            i18n.changeLanguage(lang);
            setCurLang(lang);
            localStorage.setItem('lang', JSON.stringify(lang));
        };

        const redirectIfNecessary = (newLang: any) => {
            const pathParts = location.pathname.split('/');
            if (pathParts[1] !== newLang) {
                pathParts[1] = newLang;
                navigate(pathParts.join('/'));
            }
        };

        if (location.pathname === '/') {
            getSavedLanguage();
            return;
        }

        if (location.pathname.includes('privacy-policy') || location.pathname.includes('terms-conditions')) {
            if (urlLang === 'en' || urlLang === 'de' || urlLang === 'pl') {
                updateLanguage(urlLang);
            } else {
                updateLanguage('de');
                redirectIfNecessary('de');
            }
        } else {
            if (urlLang === 'en' || urlLang === 'de' || urlLang === 'pl' || urlLang === 'zh') {
                updateLanguage(urlLang);
            } else {
                updateLanguage('de');
                redirectIfNecessary('de');
            }
        }
    }, [curLang, location.pathname]);

    return (
        // upointdms.com
        <Routes>
            {/* Main */}
            <Route path="/" element={<RootPage curView={curView} curLang={curLang} setCurLang={setCurLang} />}>
                {/* Product / uPoint DMS */}
                <Route path="/:lang" element={<ProductPage curLang={curLang} productDetailsObj={uPointProductDetails} productFeatureSection={uPointFeatureDetails} />} />
            </Route>

            {/* Product / uPoint DMS / Terms & Conditions Page */}
            <Route path="/:lang/terms-conditions" element={<UPointTermsPage curLang={curLang} setCurLang={setCurLang} />} />

            {/* Product / uPoint DMS / Privacy Policy Page */}
            <Route path="/:lang/privacy-policy" element={<UPointPrivacyPage curLang={curLang} setCurLang={setCurLang} />} />

            {/* Product / uPoint DMS / Landing Page */}
            <Route path="/:lang/landing" element={<UPointLandingPage curLang={curLang} />} />
        </Routes>
    );
};

export default App;
