import React from 'react';
import styled from 'styled-components';
import ArrowSm from '../../assets/icons/arrow-sm.svg';

const Wrapper = styled.button`
    position: relative;
    border-radius: 50%;
    width: 19rem;
    height: 19rem;
    background: var(--btn-circle-more);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        position: relative;
        z-index: 1;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &::before {
        background: var(--btn-circle-more-h-1);
        transition: transform 0.2s;
    }

    &::after {
        background: var(--btn-circle-more-h-2);
        transition: transform 0.3s;
    }

    &:hover,
    &:active {
        background: var(--btn-circle-more);
    }

    &:hover::before {
        transform: scale(1.15);
    }

    &:hover::after {
        transform: scale(1.35);
    }

    &:active::before,
    &:active::after {
        transform: scale(1);
    }
`;

interface Props {
    category: string;
    targetElId: string;
    place: ScrollLogicalPosition | undefined;
}

const ArrowDownBtn: React.FC<Props> = ({ category, targetElId, place }) => {
    return (
        <Wrapper
            className={category}
            onClick={() => {
                const targetEl = document.getElementById(targetElId);
                if (targetEl) {
                    targetEl.scrollIntoView({ block: place });
                }
            }}
        >
            <img src={ArrowSm} alt="arrow" />
        </Wrapper>
    );
};

export default ArrowDownBtn;
