import styled from 'styled-components';
import { desktopViews, tabletViews } from '../utils/media';
import { mainPageBgActiveIndex } from '../utils/common';

export const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: var(--main-top-padding) var(--main-right-padding) var(--main-bottom-padding) var(--main-left-padding);

    &.bg-primary-gradient {
        background-image: var(--bg-primary-gradient);
    }

    &.bg-primary-gradient-revert {
        background-image: var(--bg-primary-gradient-revert);
    }

    &.bg-primary {
        background-color: var(--bg-primary);
    }

    &.bg-secondary {
        background-color: var(--bg-secondary);
    }

    &.bg-tertiary {
        background-color: var(--bg-tertiary);
    }
`;

export const StyledWrapperStandard = styled(StyledWrapper)`
    padding: var(--main-top-padding) var(--main-side-padding) var(--main-bottom-padding);
`;

export const StyledInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 120rem;
    margin: 0 auto;
`;

export const CardLayoutInner = styled(StyledInner)`
    display: grid;
    align-items: stretch;
    justify-content: space-between;
    gap: 4rem;

    &.col-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &.col-3 {
        grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        gap: 3rem;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

        &.col-2,
        &.col-3 {
            grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
        }
    }
`;

export const StyledGridInner = styled(StyledInner)`
    display: grid;
    column-gap: 4rem;

    &.challenges {
        grid-template-columns: minmax(38rem, 55rem) minmax(40rem, 1fr);
    }

    &.team {
        grid-template-columns: minmax(30rem, 55rem) minmax(52rem, 1fr);
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        column-gap: 3rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        display: block;
    }
`;

export const StyledQuoteInner = styled(StyledInner)`
    max-width: 100rem;
    position: relative;
    display: grid;
    place-items: center;
    text-align: center;
    row-gap: 6rem;
    z-index: ${mainPageBgActiveIndex + 1};
    transition: all 0.5s ease-in-out;
    padding: 12rem 0 16rem;

    &.main {
        row-gap: 0;
    }

    &.product {
        padding: 40rem 0 16rem;
    }

    &.active {
        color: var(--neutral-50);
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        padding: 6rem 0 8rem;

        &.product {
            padding: 20rem 0 12rem;
        }
    }
`;

export const TitleWrapper = styled.div`
    &.title-intro-wrapper {
        margin-bottom: 4rem;
    }

    &.title-card-wrapper {
        margin-bottom: 8rem;
    }

    &.title-video-wrapper {
        margin-bottom: 6rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        &.title-card-wrapper {
            margin-bottom: 6rem;
        }

        &.title-intro-wrapper,
        &.title-video-wrapper {
            margin-bottom: 4rem;
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        &.title-card-wrapper {
            margin-bottom: 5rem;
        }

        &.title-intro-wrapper,
        &.title-video-wrapper {
            margin-bottom: 3rem;
        }
    }
`;

export const ContentTextWrapper = styled.div`
    margin-bottom: 4rem;

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        margin-bottom: 3rem;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        margin-bottom: 3rem;
    }
`;

export const IntroWrapper = styled.div`
    margin: 0 auto;
    margin-bottom: 6rem;
    padding: 0 3rem;
    text-align: center;
    max-width: 100rem;

    strong {
        font-weight: 500;
        color: var(--txt-active);
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        margin-bottom: 4rem;
    }

    /* max-width: 820px */
    @media (max-width: 820px) {
        padding: 0;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        padding: 0;
        margin-bottom: 3rem;
    }
`;

export const StyledBtn = styled.button`
    font-size: 2.1rem;
    color: var(--txt-active);
    text-transform: uppercase;
    font-weight: 500;

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 2rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        font-size: 1.9rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        font-size: 1.7rem;
    }
`;

export const Img = styled.img`
    cursor: zoom-in;
    width: 100%;
    border: 1px solid var(--neutral-300);
    border-radius: var(--img-bd-r);
`;
