import styled from 'styled-components';
import { desktopViews, tabletViews } from '../utils/media';

export const H1Title = styled.h1`
    width: 100%;
    position: relative;
    font-family: 'Ubuntu', 'Roboto Mono', sans-serif;
    color: var(--txt-active);

    span {
        padding: 0 1.2rem 0 0.3rem;
        background-repeat: no-repeat;
        background-image: linear-gradient(#d0e3c0, #d0e3c0);
        background-size: 100% 50%;
        background-position-y: 0.6rem;
    }

    &.main {
        margin-bottom: 3rem;
    }

    &.product {
        margin-bottom: 1.2rem;
    }

    font-size: 5.4rem;
    line-height: 1.4;
    font-weight: 600;
    letter-spacing: -0.6px;

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: calc(48px + 0.390625vw);
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        font-size: 4.4rem;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        font-size: 3.8rem;

        &.main {
            margin-bottom: 2.4rem;
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        font-size: 3.4rem;

        &.main {
            margin-bottom: 2rem;
        }
    }
`;

export const H2Title = styled.h2`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 4.8rem;
    line-height: 1.7;
    letter-spacing: 1px;
    margin: 0 auto;

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 4.4rem;
        line-height: 1.8;
        letter-spacing: 0.5px;
    }

    span {
        font-weight: 500;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 4.4rem;

        &.lang-pl {
            font-size: 4rem;
            letter-spacing: 0.8px;
        }
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        font-size: 4rem;

        &.lang-pl {
            font-size: 3.6rem;
            letter-spacing: 1px;
        }
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        font-size: 3.4rem;

        &.lang-pl {
            font-size: 3.2rem;
            letter-spacing: 0px;
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        font-size: 2.8rem;

        &.lang-pl {
            font-size: 2.6rem;
            letter-spacing: 0.5px;
        }
    }
`;

export const H3Title = styled.h3`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    font-size: 4.4rem;
    letter-spacing: 0.25px;

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 4rem;
        line-height: 1.7;
        letter-spacing: -0.1px;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 4rem;

        &.lang-pl {
            font-size: 3.6rem;
            letter-spacing: 0.25px;
        }
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        font-size: 3.4rem;

        &.lang-pl {
            font-size: 3.2rem;
        }
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        font-size: 2.8rem;

        &.lang-pl {
            font-size: 2.6rem;
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        font-size: 2.6rem;

        &.lang-pl {
            font-size: 2.4rem;
        }
    }
`;

export const H4Title = styled.h4`
    font-family: 'Ubuntu', 'Assistant', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    z-index: 10;
    transform: translateX(-0.5rem);
    text-align: center;

    span {
        padding: 0.5rem 1rem 0 0.5rem;
        background-repeat: no-repeat;
        background-size: 100% 50%;
        background-position-y: 0.9rem;
    }

    &.title-span-primary span {
        background-image: var(--title-span-primary-bg);
    }

    &.title-span-secondary span {
        background-image: var(--title-span-secondary-bg);
    }
    &.title-span-tertiary span {
        background-image: var(--title-span-tertiary-bg);
    }

    font-size: 3.6rem;

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 3.4rem;
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        font-size: 3.2rem;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        font-size: 3rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        font-size: 2.8rem;
    }
`;

export const H5Intro = styled.h5`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 3rem;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: 0px;
    color: var(--txt-active);

    &.intro-thin {
        font-weight: 300;
    }

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 2.8rem;
        line-height: 1.7;
        letter-spacing: -0.25px;
    }

    &.lang-pl.intro-thin {
        font-weight: 300;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 2.8rem;

        &.lang-pl {
            font-size: 2.6rem;
        }
    }

    /* max-width: 1200px */
    @media ${desktopViews.xs} {
        font-size: 2.6rem;

        &.lang-pl {
            font-size: 2.4rem;
        }
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        font-size: 2.4rem;

        &.lang-pl {
            font-size: 2.2rem;
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        font-size: 2.2rem;

        &.lang-pl {
            font-size: 2rem;
        }
    }
`;

export const Subtitle1 = styled.p`
    color: var(--txt-active);
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 2.2rem;
    letter-spacing: 0.2px;
    color: var(--txt-subtitle-01);

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.7;
        letter-spacing: 0.1px;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 2.1rem;

        &.lang-pl {
            font-size: 1.9rem;
        }
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 2rem;

        &.lang-pl {
            font-size: 1.8rem;
        }
    }
`;

export const Subtitle2 = styled.p`
    font-family: 'Assistant', 'Roboto', sans-serif;
    color: var(--txt-inactive);
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: 0.2px;

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.7;
        letter-spacing: 0.1px;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 1.9rem;

        &.lang-pl {
            font-size: 1.7rem;
        }
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 1.8rem;

        &.lang-pl {
            font-size: 1.6rem;
        }
    }
`;

export const Text1 = styled.p`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: var(--txt-body-01);

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 0.15px;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 2.1rem;

        &.lang-pl {
            font-size: 1.9rem;
        }
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 2rem;

        &.lang-pl {
            font-size: 1.8rem;
        }
    }
`;

export const Text2 = styled.p`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 2rem;
    letter-spacing: 0.25px;
    color: var(--txt-body-01);

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 0.1px;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 1.9rem;

        &.lang-pl {
            font-size: 1.7rem;
        }
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 1.8rem;

        &.lang-pl {
            font-size: 1.6rem;
        }
    }
`;

export const Text3 = styled.p`
    font-family: 'Assistant', 'Roboto', sans-serif;
    color: var(--txt-active);
    font-weight: 500;
    font-size: 1.9rem;
    letter-spacing: 0.1px;

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 1.7rem;
        line-height: 1.7;
        font-weight: 400;
        letter-spacing: 0.1px;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 1.8rem;

        &.lang-pl {
            font-size: 1.6rem;
        }
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 1.7rem;

        &.lang-pl {
            font-size: 1.5rem;
        }
    }
`;

export const Caption = styled.p``;

export const Overline = styled.p``;
