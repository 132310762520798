import { useState, useEffect } from "react";

const useScroll = (targetEl) => {
  const [scrollDirection, setScrollDirection] = useState(null);
  //   const [isHeaderActive, setHeaderActive] = useState(null);

  useEffect(() => {
    let prevScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const currentScrollY = window.scrollY;
      let hereSec = document.getElementById(targetEl);

      setScrollDirection("none");

      if (hereSec) {
        let heroHeight = hereSec.clientHeight;
        if (window.scrollY > heroHeight - 10) {
          if (currentScrollY > prevScrollY) {
            setScrollDirection("down");
          } else if (currentScrollY < prevScrollY - 5) {
            setScrollDirection("up");
          }
          prevScrollY = currentScrollY;
        }
      }
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  return scrollDirection;
};

export default useScroll;
