import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Wrapper,
    MainBannerWrapper,
    MainTitleWrapper,
    Inner,
    Section,
    ContentWrapper,
    ContentDetails,
} from './style';
import Footer from '../../sections/footer/Footer';
import LangBtns from '../../components/LangBtns';
import { Subtitle1, Text2, Text3 } from '../../components/common.styles.font';

const langArr = [
    { id: 'en', name: 'en', txt: 'EN' },
    { id: 'de', name: 'de', txt: 'DE' },
    { id: 'pl', name: 'pl', txt: 'PL' },
];

// interface Props {
//     curLang: string;
//     setCurLang: React.Dispatch<React.SetStateAction<string>>;
// }

const UPointTermsPage = ({ curLang, setCurLang }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <MainBannerWrapper>
                <MainTitleWrapper>
                    <h1>{t('uPointDMS.terms.main.title')}</h1>
                    <Text3 className={`lang-${curLang}`}>
                        {t('uPointDMS.terms.main.lastUpdated')}
                    </Text3>
                </MainTitleWrapper>

                <LangBtns
                    curLang={curLang}
                    setCurLang={setCurLang}
                    langArr={langArr}
                />
            </MainBannerWrapper>

            <Inner>
                <Section>
                    {/*---------- Introdution ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.main.introTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} section-intro`}>
                                {t('uPointDMS.terms.main.introDesc1')}
                            </Text2>
                            <Text2 className={`lang-${curLang} section-intro`}>
                                {t('uPointDMS.terms.main.introDesc2')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Intellectual Property Rights ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.rightsTitle')}
                        </Subtitle1>

                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t('uPointDMS.terms.content.rightsDesc')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Your Content ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.yourContentTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t('uPointDMS.terms.content.yourContentDesc')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Limitation of liability ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.limitationTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t('uPointDMS.terms.content.limitationDesc')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Indemnification ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.indemnificationTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t(
                                    'uPointDMS.terms.content.indemnificationDesc',
                                )}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Severability ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.severabilityTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t('uPointDMS.terms.content.severabilityDesc')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Variation of Terms ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.termsTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t('uPointDMS.terms.content.termsDesc')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Assignment ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.assignmentTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t('uPointDMS.terms.content.assignmentDesc')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Governing Law & Jurisdiction ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.terms.content.lawTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t('uPointDMS.terms.content.lawDesc')}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>
                </Section>
            </Inner>
            <Footer isHomePage={false} curLang={curLang} />
        </Wrapper>
    );
};

export default UPointTermsPage;
