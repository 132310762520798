import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Wrapper,
    MainBannerWrapper,
    MainTitleWrapper,
    Inner,
    Section,
    ContentWrapper,
    ContentDetails,
    ContentList,
    ContentListItem,
    ContactInfoWrapper,
    ItemLink,
} from './style';
import Footer from '../../sections/footer/Footer';
import LangBtns from '../../components/LangBtns';
import { Subtitle1, Text2, Text3 } from '../../components/common.styles.font';
import { contactLink, blubinaLink } from '../../utils/links';

const langArr = [
    { id: 'en', name: 'en', txt: 'EN' },
    { id: 'de', name: 'de', txt: 'DE' },
    { id: 'pl', name: 'pl', txt: 'PL' },
];

// interface Props {
//     curLang: string;
//     setCurLang: React.Dispatch<React.SetStateAction<string>>;
// }

const UPointPrivacyPage = ({ curLang, setCurLang }) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <MainBannerWrapper>
                <MainTitleWrapper>
                    <h1>{t('uPointDMS.privacyPolicy.main.title')}</h1>
                    <Text3 className={`lang-${curLang}`}>
                        {t('uPointDMS.privacyPolicy.main.lastUpdated')}
                    </Text3>
                </MainTitleWrapper>

                <LangBtns
                    curLang={curLang}
                    setCurLang={setCurLang}
                    langArr={langArr}
                />
            </MainBannerWrapper>

            <Inner>
                <Section>
                    {/*---------- Introdution ----------*/}
                    <ContentWrapper>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} section-intro`}>
                                {t('uPointDMS.privacyPolicy.main.intro1')}
                            </Text2>

                            <Text2 className={`lang-${curLang} section-intro`}>
                                {t('uPointDMS.privacyPolicy.main.intro2')}
                            </Text2>

                            <Text2 className={`lang-${curLang} section-intro`}>
                                {t('uPointDMS.privacyPolicy.main.intro3')}
                            </Text2>
                        </ContentDetails>

                        <ContentList>
                            <ContentListItem>
                                <Text2
                                    className={`lang-${curLang} list-details list-details-point-em`}
                                >
                                    {t(
                                        'uPointDMS.privacyPolicy.main.introPoint1',
                                    )}
                                </Text2>
                            </ContentListItem>
                            <ContentListItem>
                                <Text2
                                    className={`lang-${curLang} list-details list-details-point-em`}
                                >
                                    {t(
                                        'uPointDMS.privacyPolicy.main.introPoint2',
                                    )}
                                </Text2>
                            </ContentListItem>
                        </ContentList>
                    </ContentWrapper>

                    {/*---------- Cookies ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.privacyPolicy.content.cookiesTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t(
                                    'uPointDMS.privacyPolicy.content.cookiesDesc',
                                )}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Children's Privacy ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.privacyPolicy.content.childrenTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t(
                                    'uPointDMS.privacyPolicy.content.childrenDesc',
                                )}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Changes Policy ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.privacyPolicy.content.changesTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t(
                                    'uPointDMS.privacyPolicy.content.changesDesc',
                                )}
                            </Text2>
                        </ContentDetails>
                    </ContentWrapper>

                    {/*---------- Contact Us ----------*/}
                    <ContentWrapper>
                        <Subtitle1 className={`lang-${curLang} section-title`}>
                            {t('uPointDMS.privacyPolicy.content.contactTitle')}
                        </Subtitle1>
                        <ContentDetails>
                            <Text2 className={`lang-${curLang} list-details`}>
                                {t(
                                    'uPointDMS.privacyPolicy.content.contactDesc',
                                )}
                            </Text2>
                        </ContentDetails>

                        <ContactInfoWrapper>
                            <Text3 className={`lang-${curLang} company-name`}>
                                {t('uPointDMS.privacyPolicy.company.name')}
                            </Text3>
                            <Text3 className={`lang-${curLang} company-info`}>
                                {t('uPointDMS.privacyPolicy.company.address')}
                            </Text3>
                            <Text3 className={`lang-${curLang} company-info`}>
                                {t('uPointDMS.privacyPolicy.company.phone')}+49
                                89-12 50 12 46-0
                            </Text3>
                            <Text3 className={`lang-${curLang} company-info`}>
                                {t('uPointDMS.privacyPolicy.company.email')}
                                <ItemLink
                                    className={`lang-${curLang}`}
                                    href={`mailto:${contactLink}`}
                                >
                                    {contactLink}
                                </ItemLink>
                            </Text3>
                            <Text3 className={`lang-${curLang} company-info`}>
                                {t('uPointDMS.privacyPolicy.company.web')}
                                <ItemLink
                                    className={`lang-${curLang}`}
                                    href={blubinaLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {blubinaLink}
                                </ItemLink>
                            </Text3>
                        </ContactInfoWrapper>
                    </ContentWrapper>
                </Section>
            </Inner>
            <Footer curLang={curLang} />
        </Wrapper>
    );
};

export default UPointPrivacyPage;
