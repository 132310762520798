import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledInner, StyledWrapper, IntroWrapper } from '../../components/common.style';
import { H5Intro } from '../../components/common.styles.font';
import Video from '../../components/Video';

const Wrapper = styled(StyledWrapper)`
    width: 100%;
    color: var(--neutral-50);
    overflow-y: hidden;
    padding: var(--main-top-padding) var(--main-side-padding) var(--main-left-padding);
`;

const Inner = styled(StyledInner)`
    position: relative;
    text-align: center;
    max-width: 92rem;
`;

const VideoWrapper = styled.div`
    width: 100%;
`;

interface Props {
    curLang: string;
    dataName: string;
    section: {
        desc: string;
        video: any;
    };
}

const ProductIntroSection: React.FC<Props> = ({ curLang, dataName, section }) => {
    const { t } = useTranslation();

    return (
        <Wrapper id={`product-${dataName}__intro`} className="bg-tertiary">
            <Inner>
                <IntroWrapper>
                    <H5Intro className={`lang-${curLang} intro-thin`}>{t(section.desc)}</H5Intro>
                </IntroWrapper>

                <VideoWrapper>
                    <Video videoSrc={section.video} isAutoPlay={true} status={'active'} />
                </VideoWrapper>
            </Inner>
        </Wrapper>
    );
};

export default ProductIntroSection;
