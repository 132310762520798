import React from 'react';
import styled from 'styled-components';
import { tabletViews } from '../../utils/media';
import { bottomHeight } from '../../utils/common';

const Wrapper = styled.div`
    border-radius: 50px 50px 0 0;
    width: 100%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${bottomHeight}rem;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    padding: 2rem 0 1.4rem;
    background-color: var(--layout-bottom-bg);
    box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, 0.08);

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        display: flex;
    }
`;

const StepCircle = styled.button`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--progress-bg);
    transition: none;

    &.completed {
        background-color: var(--progress-bg-completed);
    }

    &.active {
        background-color: var(--progress-bg-a);
        width: 4rem;
        border-radius: 8px;
        height: 6px;
    }
`;

interface Props {
    curStep: number;
    setCurStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepProgressBottomNav: React.FC<Props> = ({ curStep, setCurStep }) => {
    return (
        <Wrapper>
            {[1, 2, 3, 4, 5].map((step) => (
                <StepCircle
                    key={`progress-bar-bottom_${step}`}
                    className={
                        curStep === step
                            ? 'active'
                            : curStep > step
                              ? 'completed'
                              : ''
                    }
                    onClick={() => setCurStep(step)}
                />
            ))}
        </Wrapper>
    );
};

export default StepProgressBottomNav;
