import styled from 'styled-components';
import { mobileViews, tabletViews } from '../../utils/media';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    font-family: 'Roboto', 'Assistant', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--txt-active);
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const MainBannerWrapper = styled.div`
    background: var(--banner-bg);
`;

export const MainTitleWrapper = styled.div`
    padding: 5rem 16rem 5rem;
    max-width: 106rem;
    margin: 0 auto;

    h1 {
        color: #000;
        font-family: 'Ubuntu', 'Roboto', sans-serif;
        font-weight: 600;
        color: #000;
        font-size: 3.2rem;
        margin-bottom: 1.2rem;
        word-break: break-all;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        padding: 5rem 14rem 5rem 6rem;
        max-width: 100%;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding: 5rem 4rem 5rem;
    }

    /* max-width: 480px */
    @media ${mobileViews.xlg} {
        padding: 6rem 2rem 5rem;
    }
`;

export const Inner = styled.div`
    position: relative;
    padding: 5rem 16rem 3rem;
    max-width: 106rem;
    margin: 0 auto;

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        padding: 4rem 14rem 3rem 6rem;
        max-width: 100%;
        margin: 0 auto;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding: 4rem 4rem 3rem;
    }

    /* max-width: 480px */
    @media ${mobileViews.xlg} {
        padding: 3rem 2rem 2rem;
    }
`;

export const Section = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 6rem;
`;

export const ContentWrapper = styled.div`
    margin-bottom: 4.4rem;

    .section-title {
        font-weight: 600;
        color: var(--txt-active);
        margin-bottom: 1rem;
    }
`;

export const ContentDetails = styled.div`
    .section-intro {
        color: var(--txt-inactive);
        margin-bottom: 3rem;
    }

    .list-title {
        color: var(--txt-active);
        margin-bottom: 0.8rem;
    }

    .list-details {
        font-weight: 400;
        color: var(--txt-inactive);
        margin-bottom: 2rem;
    }

    .list-details-title {
        font-weight: 500;
        color: var(--txt-active);
        margin-bottom: 1rem;
    }

    .list-details-point {
        color: var(--txt-active);
        margin-bottom: 1rem;
    }
`;

export const ContentList = styled.ul`
    padding-left: 5rem;
`;

export const ContentListItem = styled.li`
    position: relative;
    left: -10px;
    list-style-type: disc;

    .list-details-point-em {
        color: var(--txt-active);
    }
`;

export const ContactInfoWrapper = styled.div`
    margin: 4rem 0 2rem;

    .company-name {
        color: var(--txt-active);
    }

    .company-info {
        font-weight: 400;
        color: var(--txt-inactive);
        margin-bottom: 0.4rem;
    }
`;

export const ItemLink = styled.a`
    font-family: 'Assistant', 'Roboto', sans-serif;
    position: relative;
    margin: 0 0.4rem;
    color: #0062ae;
    font-weight: 500;
    font-size: 1.9rem;
    letter-spacing: 0.1px;

    &.lang-pl {
        font-family: 'Noto Sans', 'Roboto', sans-serif;
        font-size: 1.7rem;
        line-height: 1.7;
        font-weight: 400;
        letter-spacing: 0.1px;
    }

    &.mg-0 {
        margin: 0;
    }

    .icon {
        transform: translateY(4px);
        width: 1.6rem;
        margin-left: 0.8rem;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        font-size: 1.8rem;

        &.lang-pl {
            font-size: 1.6rem;
        }
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 1.7rem;

        &.lang-pl {
            font-size: 1.5rem;
        }
    }
`;
